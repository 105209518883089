.inter-300 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
}

.inter-400 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}

.inter-500 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
}

.inter-700 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
}

.hero-section {
  background-image: url("../../Assets/hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-size: auto; */
  background-position: center center;
  height: 500px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
}

.hero-main {
  font-size: 55px;
  line-height: 82.5px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
}

.hero-text {
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  margin-top: 40px;
  word-spacing: 1.2px;
}

.app-filters {
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  height: 100px;
  box-shadow: 0px 0.82px 1.65px 0px #0000004d;
  z-index: 2;
}

.app-filters-mob-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: left;
  color: #134b7e;
}

.app-filters-mob {
  background-color: #ffffff;
  width: 80%;
  height: 100px;
  box-shadow: 0px 0.82px 1.65px 0px #0000004d;
  z-index: 2;
  border-radius: 5px;
  padding: 10px;
}

.app-icons {
  display: grid;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  padding: 10px;
}

.app-icons:hover {
  cursor: pointer;
  /* background: #f3f5f8; */
}

.app-icon {
  position: relative;
  height: 30px;
  width: 30px;
  left: 50%;
  translate: -50%;
  margin: 5px 0px;
  display: grid;
}

.icon-text {
  text-align: center;
  color: #134b7e;
  font-weight: 400;
  font-size: 20px;
}

.app-icon-highlight-section {
  display: "inherit";
  width: "100%";
  background: "#F3F5F8";
  padding: "5px 55px";
  border-radius: "5px";
}

.showcase-app-col {
  height: 370px;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 20px 0px;
  box-shadow: 0px 21.03px 25.23px -4.21px #10182814;
}

.showcase-app-col-mob {
  height: 300px;
}

.bookmark-icon {
  position: relative;
  z-index: 4;
  cursor: pointer;
  color: #2f80ed88;
  top: 50px;
  left: -1.5vw;
  background-color: #ffffffb2;
  border-radius: 5px;
  height: 35px;
  width: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookmark-icon-fill {
  color: #2f80ed;
}

.bookmark-icon:hover {
  color: #2f80ed;
  box-shadow: 0px 0.82px 1.4px 1px #ffffffd2;
}

.showcase-app-image {
  position: relative;
  width: 90%;
  height: 150px;
  border-radius: 12px;
  margin: 5px auto;
  left: 50%;
  translate: -50%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3) !important;
  filter: brightness(90%);
}

.showcase-app-name {
  position: relative;
  display: inline-flex;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  font-weight: 500;
  color: #333333;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 90%;
  height: 30px !important;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, 0%);
}

.showcase-app-desc {
  position: relative;
  display: inline-flex;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #667085;
  width: 90%;
  height: 55px !important;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, 0%);
  text-overflow: ellipsis;
}

.showcase-app-btn {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 90%;
  border-radius: 4px !important;
  background: #2f80ed !important;
  box-shadow: 0px 1.05px 2.1px 0px #1018280d !important;
}

.insights-row {
  margin-top: 20px !important;
}

.insights-main-heading {
  font-size: 44px;
  font-weight: 500;
  line-height: 53.25px;
  color: #333333;
}

.insights-app-image {
  position: relative;
  width: 100%;
  height: 300px;
  filter: brightness(90%);
}

.insights-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #89a5be;
  margin-left: 5px;
}

.insights-heading {
  font-size: 36px;
  font-weight: 400;
  line-height: 40.32px;
  text-align: left;
  color: #333333;
}

.insights-desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #333333;
}

.insights-date {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #89a5be;
}

.insights-btn {
  border: 1px solid #2f80ed !important;
  box-shadow: 0px 1.05px 2.1px 0px #1018280d !important;
  color: #2f80ed !important;
  font-size: 16.82px !important;
  line-height: 25.23px !important;
}

.insights-btn:hover {
  color: white !important;
  background-color: #2f80ed !important;
}

.fon-insights-main-row {
  background: #001a2d;
  margin: 0px !important;
  padding-bottom: 50px !important;
}

.fon-insights-heading {
  font-size: 44px;
  font-weight: 500;
  line-height: 53.25px;
  text-align: left;
  color: #ffffff;
  margin-top: 50px;
}

.fon-insights-subheading,
.fon-insights-contact-us {
  font-size: 28px;
  font-weight: 500;
  line-height: 33.89px;
  text-align: left;
  color: #ffffff;
}

.fon-insights-contact-phone,
.fon-insights-contact-email {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  margin-top: 30px !important;
}

.fon-insights-tile {
  background: #002d4e;
  box-shadow: 0px 1px 3px 1px #00000026;
  height: 80px;
  border-radius: 8px;
  margin: 8px 0px;
}

.fon-insights-app-image {
  position: relative;
  top: 50%;
  left: 20%;
  transform: translate(10%, -50%);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.fon-insights-tile-name {
  position: relative;
  top: 16px;
  font-size: 18.77px;
  font-weight: 500;
  line-height: 22.72px;
  text-align: left;
  color: #ffffff;
}

.fon-insights-tile-position {
  position: relative;
  top: -1px;
  font-family: Inter;
  font-size: 15.36px;
  font-weight: 300;
  line-height: 18.59px;
  color: #ffffff;
  text-align: left;
}

@media only screen and (width > 2000px) {
  .showcase-app-image {
    height: 210px;
  }

  .showcase-app-col {
    height: 400px;
  }

  .showcase-app-desc {
    height: 20px !important;
  }
}
