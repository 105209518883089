.navbar,
.offcanvas-body {
  background-color: #002d4e !important;
}

.navbar-pc {
  padding: 10px 30px !important;
}

.nav-link {
  color: #ffffff !important;
}

.nav-search {
  border-radius: 2px !important;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 1px 1px 20px black;
}

.circle {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  color: #111;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 1px solid #d0d4da;
  font-weight: 400;
}

.nav-search-bar-pc {
  height: 40px !important;
  width: 310px;
  margin-right: 0px;
}

.nav-search-bar-mob {
  height: 40px !important;
  width: 60px;
  transition: width 0.5s;
  margin-right: -10px;
}

.nav-search-bar-mob:focus-within {
  height: 40px !important;
  width: 35vw;
  margin-right: -10px;
}

.search-icon {
  position: relative;
  right: 45px;
  color: black;
  line-height: 25px;
  color: #444;
}

.search-icon-mob {
  right: 32px;
}

.n-visibility {
  visibility: hidden;
}
